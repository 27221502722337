<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { getEnergyLabel, postEnergyLabel, patchEnergyLabel, severityCode, deleteEnergyLabelById } from "@/services/api/location.api";
import { getLabelSources } from "@/services/api/masterdata.api";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import _ from "lodash";
import DateInput from "@/components/general/DateInput.vue";
import EnergyLabelSearch from "@/components/location/EnergyLabelSearch.vue";
import { navigateToLocation } from "@/composables/general.js";
import AlertOkCancel from "@/components/general/AlertOkCancel.vue";
import router from "@/router/index";

const { formatDateUtcDT } = useDateTimeFormatter();

setInteractionMode("eager");

const props = defineProps({
  id: String,
  locationReference: String,

  addModus: {
    type: Boolean,
    default: false,
  },
});

const label = ref({
  id: undefined,
  energyLabel: undefined,
  energyIndex: undefined,
  sourceKey: undefined,
  sourceReference: undefined,
  locationReference: undefined,
  energyDemand: undefined,
  primaryFossilEnergy: undefined,
  primaryFossilEnergyForfaitair: undefined,
  shareOfRenewableEnergy: undefined,
  recordedDate: undefined,
  registrationDate: undefined,
  registrationNumber: undefined,
  modBy: undefined,
  mutDat: undefined,
  usableSurface: undefined,
  usableLossSurface: undefined,
  usableGeometry: undefined,
  co2: undefined,
  demandHeatNet: undefined,
  energyStandard: undefined,
  energyRenewable: undefined,
  usageElectricity: undefined,
  usageGas: undefined,
  usageHeat: undefined,
  fossilEnergyUsageNonPrimary: undefined,
  totalUsageNonPrimary: undefined,
  usageVentilation: undefined,
  usageHeating: undefined,
  usageWater: undefined,
  usageCooling: undefined,
  usageHumidification: undefined,
  usageDehumidification: undefined,
  usageLighting: undefined,
  usageAuxiliaryElectrical: undefined,
  usageAuxiliaryPilotFlame: undefined,
  usageElectricityGeneration: undefined,
  demandHeat: undefined,
  demandHotWater1: undefined,
  demandHotWater2: undefined,
  demandCold: undefined,
});

const energyLabels = ref(["A+++++", "A++++", "A+++", "A++", "A+", "A", "B", "C", "D", "E", "F", "G", ""]);
const labelSources = ref([]);

const dataMustBeRetrieved = ref(false);

const labelSourcesLoaded = ref(false);
const isSaving = ref(false);

const masterDataLoaded = computed(() => labelSourcesLoaded.value);
const mutDateFormatted = computed(() => formatDateUtcDT(label.value.mutDat));
const showEdit = computed(() => false);
const disableSave = computed(() => (!props.addModus && !label.value.id) || isSaving.value);

const modalDeleteOpen = ref(false);
const modalDeleteBody = ref("");

watch(
  () => props.id,
  () => {
    if (!props.addModus) {
      dataMustBeRetrieved.value = true;
      fetchData();
    }
  },
  { immediate: true }
);

watch(
  () => masterDataLoaded.value,
  () => {
    fetchData();
  }
);

onMounted(() => {
  loadLabelSources();
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  if (dataMustBeRetrieved.value && masterDataLoaded.value) {
    const response = await getEnergyLabel(props.locationReference, props.id);
    console.log("EnergyLabelDetail fetchData, response:", response.data);

    if (response && !response.error) {
      label.value = response.data;
    } else {
      proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
    }
    dataMustBeRetrieved.value = false;
  }
}

async function loadLabelSources() {
  const response = await getLabelSources();
  if (response && !response.error) {
    console.log("EnergyLabelDetail loadLabelSources, response:", response.data);
    labelSources.value = _.sortBy([...response.data.keyDescList], "description");
    labelSourcesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de bronnen is mislukt! " + response.error);
  }
}

function checkDateValid() {
  return { on: ["input"] };
}

async function selectedEnergyLabel(selectedLabel) {
  console.log("EnergyLabelDetail selectedEnergyLabel, label: ", label.value, selectedLabel);
  label.value.energyIndex = selectedLabel.energyIndex;
  label.value.energyLabel = selectedLabel.energyLabel;
  label.value.sourceKey = selectedLabel.sourceKey;
  label.value.sourceReference = selectedLabel.sourceReference;
  label.value.locationReference = selectedLabel.locationReference;
  label.value.energyDemand = selectedLabel.energyDemand;
  label.value.primaryFossilEnergy = selectedLabel.primaryFossilEnergy;
  label.value.primaryFossilEnergyForfaitair = selectedLabel.primaryFossilEnergyForfaitair;
  label.value.shareOfRenewableEnergy = selectedLabel.shareOfRenewableEnergy;
  label.value.recordedDate = selectedLabel.recordedDate;
  label.value.registrationDate = selectedLabel.registrationDate;
  label.value.modBy = selectedLabel.modBy;
  label.value.mutDat = selectedLabel.mutDat;
  label.value.usableSurface = selectedLabel.usableSurface;
  label.value.usableLossSurface = selectedLabel.usableLossSurface;
  label.value.usableGeometry = selectedLabel.usableGeometry;
  label.value.co2 = selectedLabel.co2;
  label.value.demandHeatNet = selectedLabel.demandHeatNet;
  label.value.energyStandard = selectedLabel.energyStandard;
  label.value.energyRenewable = selectedLabel.energyRenewable;
  label.value.usageElectricity = selectedLabel.usageElectricity;
  label.value.usageGas = selectedLabel.usageGas;
  label.value.usageHeat = selectedLabel.usageHeat;
  label.value.fossilEnergyUsageNonPrimary = selectedLabel.fossilEnergyUsageNonPrimary;
  label.value.totalUsageNonPrimary = selectedLabel.totalUsageNonPrimary;
  label.value.usageVentilation = selectedLabel.usageVentilation;
  label.value.usageHeating = selectedLabel.usageHeating;
  label.value.usageWater = selectedLabel.usageWater;
  label.value.usageCooling = selectedLabel.usageCooling;
  label.value.usageHumidification = selectedLabel.usageHumidification;
  label.value.usageDehumidification = selectedLabel.usageDehumidification;
  label.value.usageLighting = selectedLabel.usageLighting;
  label.value.usageAuxiliaryElectrical = selectedLabel.usageAuxiliaryElectrical;
  label.value.usageAuxiliaryPilotFlame = selectedLabel.usageAuxiliaryPilotFlame;
  label.value.usageElectricityGeneration = selectedLabel.usageElectricityGeneration;
  label.value.demandHeat = selectedLabel.demandHeat;
  label.value.demandHotWater1 = selectedLabel.demandHotWater1;
  label.value.demandCold = selectedLabel.demandCold;
  label.value.demandHotWater2 = selectedLabel.demandHotWater2;
}

async function onCancel() {
  navigateToLocation(props.locationReference);
}

const obs = ref(null);

async function onSave() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let response = undefined;
    if (props.addModus) {
      response = await postEnergyLabel(props.locationReference, label.value);
      if (response.data != undefined) {
        proxy.$toaster.success("Gegevens van dit label zijn successvol toegevoegd.");
        navigateToLocation(props.locationReference);
      } else {
        proxy.$toaster.error("Bewaren van label is mislukt! " + response.error);
      }
    } else {
      response = await patchEnergyLabel(props.locationReference, props.id, label.value);
      console.log("EnergyLabelDetail onSave: ", response);
      switch (response.severity) {
        case severityCode.hint:
        case severityCode.warning:
          proxy.$toaster.warning(response.message);
          break;
        case severityCode.error:
          proxy.$toaster.error("Bewaren van label is mislukt! " + response.error);
          break;
        default:
          proxy.$toaster.success("Gegevens van dit label zijn successvol gewijzigd.");
          navigateToLocation(props.locationReference);
          break;
      }
    }
  }
  isSaving.value = false;
}

function onDeleteLabel() {
  modalDeleteBody.value = `Weet u zeker dat u het label wilt verwijderen?`
  modalDeleteOpen.value = true;
}

async function onDeleteLabelDialog(resultValue){
  modalDeleteOpen.value = false;
  isSaving.value = true;
  if (resultValue === "DELETE")
  {
    var response = await deleteEnergyLabelById(props.locationReference, props.id); 
    switch (response.severity) {
      case severityCode.error:
        proxy.$toaster.error("Het verwijderen van het label is mislukt! " + response.error);
        break;
      case severityCode.hint:
      case severityCode.warning:
          proxy.$toaster.warning(response.message);
          break;
      case severityCode.success || severityCode.none:
        proxy.$toaster.success("Het verwijderen van het label is gelukt!");
        router.push({
          name: "Location",
          params: { locationReference: props.locationReference },
        });
        break;
    }
  }
  isSaving.value = false;


}

</script>

<template>
  <v-container fluid pa-0 class="app-location">
    <header class="app-welcome-message">
      <h2><span v-if="addModus">Label toevoegen</span><span v-else>Label wijzigen</span></h2>
    </header>
    <ValidationObserver ref="obs">
      <v-form>
        <v-container fluid pa-0>
          <v-row>
            <v-col>
              <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
                <v-container class="boxed" fluid>
                  <header>Label</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Label" rules="required" v-slot="{ errors }">
                        <v-select
                          hide-details="auto"
                          label="Label"
                          :items="energyLabels"
                          :value="label.energyLabel"
                          @input="label.energyLabel = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="EnergyIndex" rules="decimal:4|between:0,9" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Energie-index"
                          :value="label.energyIndex"
                          @input="label.energyIndex = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Bron" rules="required" v-slot="{ errors }">
                        <v-select
                          hide-details="auto"
                          label="Bron"
                          :items="labelSources"
                          item-text="description"
                          item-value="key"
                          v-model="label.sourceKey"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field hide-details="auto" label="Bron referentie" persistent-placeholder v-model="label.sourceReference" :readonly="showEdit" :outlined="!showEdit"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="boxed" fluid>
                  <header>Energie</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="EnergyRequirement" rules="decimal:4|between:0,9999999.99" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="EP 1: Energiebehoefte [kWh/m²]"
                          :value="label.energyDemand"
                          @input="label.energyDemand = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="PrimaryFossilEnergyUse" rules="decimal:2|between:0,9999999.99" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="EP 2: Primair fossiel energieverbruik [kWh/m²]"
                          :value="label.primaryFossilEnergy"
                          @input="label.primaryFossilEnergy = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="PrimaryFossilEnergyForfaitair" rules="decimal:2|between:0,9999999.99" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="EP 2 EMG forf.: Primair fossiel energieverbruik [kWh/m²]"
                          :value="label.primaryFossilEnergyForfaitair"
                          @input="label.primaryFossilEnergyForfaitair = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="ShareOfRenewableEnergy" rules="decimal:4|between:0,9999999.99" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="EP 3: Aandeel hernieuwbare energie [kWh/m²]"
                          :value="label.shareOfRenewableEnergy"
                          @input="label.shareOfRenewableEnergy = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>
              </masonry>

              <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
                <v-container class="boxed" fluid>
                  <header>Kenmerken energielabel</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsableSurface" rules="decimal:2|between:0,9999999.99" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Ag: Gebruiksoppervlakte [m²]"
                          :value="label.usableSurface"
                          @input="label.usableSurface = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsableLossSurface" rules="decimal:2|between:0,9999999.99" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Als: Verliesoppervlakte [m²]"
                          :value="label.usableLossSurface"
                          @input="label.usableLossSurface = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsableGeometry" rules="decimal:2|between:0,9999999.99" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Als/Ag: Geometrieverhouding [-]"
                          :value="label.usableGeometry"
                          @input="label.usableGeometry = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="CO2" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="CO₂ uitstoot [kg]"
                          :value="label.co2"
                          @input="label.co2 = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="DemandHeatNet" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Netto warmtebehoefte (EPV) [kWh/m²]"
                          :value="label.demandHeatNet"
                          @input="label.demandHeatNet = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="EnergyStandard" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Standaard [kWh/m²]"
                          :value="label.energyStandard"
                          @input="label.energyStandard = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="EnergyRenewable" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Hernieuwbare energie [kWh/m²]"
                          :value="label.energyRenewable"
                          @input="label.energyRenewable = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageElectricity" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Elektriciteitsverbruik [kWh]"
                          :value="label.usageElectricity"
                          @input="label.usageElectricity = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageGas" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Gasverbruik [m³ aeq]"
                          :value="label.usageGas"
                          @input="label.usageGas = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageHeat" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Warmteverbruik [GJ]"
                          :value="label.usageHeat"
                          @input="label.usageHeat = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="boxed" fluid :class="{ noBorders: false }">
                  <header>Energiebalans</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="FossilEnergyUsageNonPrimary" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="EP 2 niet primair [kWh/m²]"
                          :value="label.fossilEnergyUsageNonPrimary"
                          @input="label.fossilEnergyUsageNonPrimary = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="TotalUsageNonPrimary" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Totale deelposten (niet primair) [kWh]"
                          :value="label.totalUsageNonPrimary"
                          @input="label.totalUsageNonPrimary = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageVentilation" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Ventilatie [kWh]"
                          :value="label.usageVentilation"
                          @input="label.usageVentilation = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageHeating" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Verwarming [kWh]"
                          :value="label.usageHeating"
                          @input="label.usageHeating = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageWater" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Tapwater [kWh]"
                          :value="label.usageWater"
                          @input="label.usageWater = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageCooling" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Koeling [kWh]"
                          :value="label.usageCooling"
                          @input="label.usageCooling = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageHumidification" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Bevochtiging [kWh]"
                          :value="label.usageHumidification"
                          @input="label.usageHumidification = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageDehumidification" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Ontvochtiging [kWh]"
                          :value="label.usageDehumidification"
                          @input="label.usageDehumidification = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageLighting" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Verlichting [kWh]"
                          :value="label.usageLighting"
                          @input="label.usageLighting = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageAuxiliaryElectrical" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Hulpenergie elektrisch [kWh]"
                          :value="label.usageAuxiliaryElectrical"
                          @input="label.usageAuxiliaryElectrical = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageAuxiliaryPilotFlame" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Hulpenergie waakvlam [kWh]"
                          :value="label.usageAuxiliaryPilotFlame"
                          @input="label.usageAuxiliaryPilotFlame = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="UsageElectricityGeneration" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Elektriciteitsopwekking [kWh]"
                          :value="label.usageElectricityGeneration"
                          @input="label.usageElectricityGeneration = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>
              </masonry>

              <masonry :cols="{ default: 1 }" :gutter="12">
                <v-container class="boxed" fluid>
                  <header>Warmtebehoefte</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="DemandHeat" rules="decimal:2|between:0,9999999.99" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Warmtebehoefte (QH;nod;in) [kWh]"
                          :value="label.demandHeat"
                          @input="label.demandHeat = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="DemandHotWater1" rules="decimal:2|between:0,9999999.99" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Warmtapwaterbehoefte systeem 1 [kWh]"
                          :value="label.demandHotWater1"
                          @input="label.demandHotWater1 = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="DemandCold" rules="decimal:2|between:0,9999999.99" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Koudebehoefte [kWh]"
                          :value="label.demandCold"
                          @input="label.demandCold = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="DemandHotWater2" rules="decimal:2|between:0,9999999.99" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Warmtapwaterbehoefte systeem 2 [kWh]"
                          :value="label.demandHotWater2"
                          @input="label.demandHotWater2 = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>
              </masonry>

              <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
                <v-container class="boxed" fluid>
                  <header>Geldigheid</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Opnamedatum" ref="recordedDate" rules="required|date_between" v-slot="{ errors }" :mode="checkDateValid">
                        <date-input
                          v-model="label.recordedDate"
                          label="Opnamedatum (bepalend voor de leeftijd van het label)"
                          :errors="errors"
                          :readonly="showEdit"
                          rules="required"
                          :outlined="!showEdit"
                          persistent-placeholder
                        ></date-input>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Registratiedatum" ref="registrationDate" rules="date_between" v-slot="{ errors }" :mode="checkDateValid">
                        <date-input
                          v-model="label.registrationDate"
                          label="Registratiedatum"
                          :errors="errors"
                          :readonly="showEdit"
                          rules="required"
                          :outlined="!showEdit"
                          persistent-placeholder
                        ></date-input>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Registratienummer" rules="between:0,999999999" v-slot="{ errors }">
                        <v-text-field
                          type="number"
                          hide-details="auto"
                          label="Registratienummer"
                          :value="label.registrationNumber"
                          @input="label.registrationNumber = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :readonly="showEdit"
                          :outlined="!showEdit"
                          persistent-placeholder
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="boxed" fluid :class="{ noBorders: false }">
                  <header>Aanpassing</header>
                  <v-row>
                    <v-col>
                      <v-text-field hide-details="auto" label="Door" v-model="label.modBy" readonly :outlined="false" persistent-placeholder class="noBorders"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field hide-details="auto" label="Datum" v-model="mutDateFormatted" readonly :outlined="false" persistent-placeholder class="noBorders"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </masonry>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid>
          <v-row>
            <v-col cols="auto" class="form-group">
              <v-btn class="primary" v-on:click="onSave()" :disabled="disableSave">
                <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                Opslaan
              </v-btn>
            </v-col>
            <v-col cols="auto" class="form-group">
              <v-btn v-on:click="onCancel()">
                <v-icon dark left>mdi-cancel</v-icon>
                Annuleren
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="auto" class="col col-auto" v-if="!addModus">
              <v-btn class="secondary" v-on:click="onDeleteLabel" :disabled="isSaving">
                <v-icon dark left>mdi-delete</v-icon>
                Verwijderen
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
    <energy-label-search v-show="addModus" @selected="selectedEnergyLabel"></energy-label-search>
    <!-- <energy-label-search v-show="addModus" bagId="0345010002045206" @selected="selectedEnergyLabel"></energy-label-search> -->
    <!-- <energy-label-search v-show="addModus" postalCode="4207NS" houseNumber="41" @selected="selectedEnergyLabel"></energy-label-search> -->
    <AlertOkCancel title="Verwijderen energielabel" showDelete :body="modalDeleteBody" :persistent="false" :modalOpen="modalDeleteOpen" @onDialogResult="onDeleteLabelDialog" />
  </v-container>
</template>
