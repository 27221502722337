<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import { getEPonlineOverview } from "@/services/api/location.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";

const emits = defineEmits(["selected"]);
const { formatDateCustom } = useDateTimeFormatter();

setInteractionMode("eager");

const props = defineProps({
  bagId: {
    type: String,
    default: undefined,
  },
  postalCode: {
    type: String,
    default: undefined,
  },
  houseNumber: {
    type: String,
    default: undefined,
  },
  houseNumberAddition: {
    type: String,
    default: undefined,
  },
});

const bag = ref({
  id: undefined,
  postalCode: undefined,
  houseNumber: undefined,
  houseNumberAddition: undefined,
});
const bagLoading = ref(false);
const bagOverview = ref([]);

const headers = computed(() => {
  let h = [{ text: "", value: "actions", sortable: false, width: 20 }];
  h.push(
    { text: "Pand", value: "bag.buildingId" },
    { text: "Verblijfsobject", value: "bag.residenceId" },
    { text: "Energy label", value: "energyLabel.energyLabel" },
    { text: "Energy index", value: "energyLabel.energyIndex" },
    { text: "Bouwjaar", value: "bag.constructionDate" },
    { text: "Type", value: "bag.typeDescription" },
    { text: "GO", value: "bag.go" },
    { text: "Adr. Type", value: "bag.addressTypeDescription" },
    { text: "Adres", value: "bag.composedAddress" },
    { text: "Postcode", value: "bag.postalCode" },
    { text: "Woonplaats", value: "bag.city" }
  );
  return h;
});
const propsAreProvided = computed(() => {
  console.log("propsAreProvided, bagId, postalCode, houseNumber, houseNumberAddition: ", props.bagId, props.postalCode, props.houseNumber, props.houseNumberAddition);
  return props.bagId !== undefined || props.postalCode !== undefined || props.houseNumber !== undefined || props.houseNumberAddition !== undefined;
});

watch(
  () => [props.bagId, props.postalCode, props.houseNumber, props.houseNumberAddition],
  () => {
    getEnergyLabels(props.bagId, props.postalCode, props.houseNumber, props.houseNumberAddition);
  }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

const searchEnergyLabel = ref(null);

async function onSearchEnergyLabel() {
  const valid = await searchEnergyLabel.value.validate();
  if (valid) {
    getEnergyLabels(bag.value.id, bag.value.postalCode, bag.value.houseNumber, bag.value.houseNumberAddition);
  }
}

async function getEnergyLabels(bagId, postalCode, houseNumber, houseNumberAddition) {
  bagLoading.value = true;
  bagOverview.value = [];
  const response = await getEPonlineOverview(bagId, postalCode, houseNumber, houseNumberAddition);
  if (response && !response.error) {
    if (response.data) {
      console.log("getEnergyLabels, response.data: ", response.data);
      bagOverview.value = response.data.map((el) => ({ ...el, key: `${el.bag.buildingId}:${el.bag.residenceId}:${el.bag.numberId}` }));
    }
  } else {
    proxy.$toaster.error("Ophalen van de BAG gegevens is mislukt! " + response.error);
  }
  bagLoading.value = false;
}

function itemRowBackground(item) {
  return item.bag.residenceId ? (item.bag.primaryAddress ? "bagResidence" : "bagResidenceAlternative") : "bagBuilding";
}

function onSelectLabel(item) {
  console.log("EnergyLabelSearch, onSelectLabel, item.energyLabel: ", item.energyLabel);
  emits("selected", item.energyLabel);
}
</script>

<template>
  <ValidationObserver ref="searchEnergyLabel">
    <v-form>
      <masonry :cols="{ default: 1 }" :gutter="12" :class="{ noBorders: true }">
        <v-container class="boxed" fluid>
          <header>Gegevens overnemen van EP-Online</header>
          <v-row v-if="!propsAreProvided">
            <v-col>
              <ValidationProvider name="BagId" rules="numeric" v-slot="{ errors }">
                <v-text-field hide-details="auto" label="ID" outlined persistent-placeholder v-model="bag.id" :error-messages="errors"></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="auto" align-self="center"> of </v-col>
            <v-col>
              <ValidationProvider name="Postcode" rules="postalcode" v-slot="{ errors }">
                <v-text-field hide-details="auto" label="Postcode" outlined persistent-placeholder v-model="bag.postalCode" :error-messages="errors"></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col>
              <v-text-field hide-details="auto" label="Huisnummer" outlined persistent-placeholder v-model="bag.houseNumber"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field hide-details="auto" label="Toevoeging" outlined persistent-placeholder v-model="bag.houseNumberAddition"></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="!propsAreProvided">
            <v-col cols="auto" class="form-group">
              <v-btn class="primary" v-on:click="onSearchEnergyLabel()">
                <v-icon dark left>mdi-magnify</v-icon>
                Zoek
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                class="bag"
                dense
                show-select
                disable-sort
                disable-pagination
                :headers="headers"
                hide-default-footer
                :items="bagOverview"
                :loading="bagLoading"
                :item-class="itemRowBackground"
                item-key="key"
              >
                <template #[`header.data-table-select`]></template>

                <template #[`item.data-table-select`]="{ item }">
                  <template v-if="item.bag.residenceId">
                    <v-btn @click="onSelectLabel(item)" icon small plain>
                      <v-icon small> mdi-forward </v-icon>
                    </v-btn>
                  </template>
                </template>

                <template v-slot:[`item.bag.constructionDate`]="{ item }">
                  {{ formatDateCustom(item.bag.constructionDate, "YYYY") }}
                </template>
                <template v-slot:[`item.bag.buildingId`]="{ item }">
                  <template v-if="!item.bag.residenceId"> {{ item.bag.buildingId }}</template>
                </template>
                <template v-slot:[`item.bag.residenceId`]="{ item }">
                  <template v-if="item.bag.primaryAddress"> {{ item.bag.residenceId }}</template>
                </template>
                <template v-slot:[`item.bag.go`]="{ item }">
                  <template v-if="!item.bag.residenceId || item.bag.primaryAddress"> {{ item.bag.go }}</template>
                </template>
                <template v-slot:[`item.bag.typeDescription`]="{ item }">
                  <template v-if="!item.bag.residenceId || item.bag.primaryAddress"> {{ item.bag.typeDescription }}</template>
                </template>
                <template v-slot:[`item.bag.addressTypeDescription`]="{ item }">
                  <template> {{ item.bag.addressTypeDescription }}</template>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </masonry>
    </v-form>
  </ValidationObserver>
</template>
